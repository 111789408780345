import React from "react";
import styles from "./FooterAffiliations.module.css";

function FooterAffiliations() {
  return (
    <div className={styles.el}>
      <div className={styles.heading}>Affiliations</div>
      <div className={styles.affiliations}>
        <a
          className={styles.affiliation}
          href="https://www.esica.org/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="Eastern States Insulation Contractors Association"
            src="https://images.ctfassets.net/gnax899ipmuy/5HzyR2MxE5oBQaZNa0GyNn/656630b5c8c216c6b5d6cd8c6801010b/esica-logo.png?w=250&fm=jpg"
          />
        </a>
        <a
          className={styles.affiliation}
          href="http://www.insulators24.org/index.aspx"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="International Association of Heat and Frost Insulators and Allied Workers, Local #24"
            src="https://images.ctfassets.net/gnax899ipmuy/7ztstSnxjaAwDC6niuZP9W/8c46aadcc2cec5447430fded2221fdc1/local24.jpg?w=140"
          />
        </a>
        <a
          className={styles.affiliation}
          href="https://insulation.org/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="National Insulation Association (NIA)"
            src="https://images.ctfassets.net/gnax899ipmuy/1bmsCIQhDbmVCz3znEfp5z/23d27e0c461b058783ed83c67a221e28/nia-logo.png?w=250"
          />
        </a>
        <a
          className={styles.affiliation}
          href="https://www.wbdg.org/guides-specifications/mechanical-insulation-design-guide"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="Mechanical Insulation Design Guide"
            src="https://images.ctfassets.net/gnax899ipmuy/2D2zcyLmri8zhBIaHByOJa/74b2a842a4011e3f3e6f554e3e28c44d/midg.jpg?w=160"
          />
        </a>
        <a
          className={styles.affiliation}
          href="https://insulationinstitute.org/tools-resources/free-3e-plus/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="3E Plus Energy Appraisals"
            src="https://images.ctfassets.net/gnax899ipmuy/3hciCFxizXlarvDD31dg8/2e86952e0d28a785cbd8c630305504a3/3e.jpg?w=50"
          />
        </a>
      </div>
    </div>
  );
}

export default FooterAffiliations;
