import { Link } from "gatsby";
import React from "react";
import styles from "./HeaderMenuMobile.module.css";

function HeaderMenuMobile(props) {
  return (
    <div className={`${styles.el} ${props.active && styles.active}`}>
      <div className={styles.nav}>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/about/"
        >
          About
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/team/"
        >
          Team
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/press/"
        >
          Press
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/services/"
        >
          Services
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/case-studies/"
        >
          Case Studies
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/clients/"
        >
          Clients
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/photos/"
        >
          Photos
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/contact/"
        >
          Contact
        </Link>
      </div>
    </div>
  );
}

export default HeaderMenuMobile;
