import { Link } from "gatsby";
import React from "react";
import styles from "./FooterMenu.module.css";

function FooterMenu() {
  return (
    <div className={styles.el}>
      <div className={styles.heading}>Contact</div>
      <div className={styles.contacts}>
        <a
          className={styles.link}
          href="tel:(301)-378-0013
"
        >
          (301)-378-0013
        </a>
        <a className={styles.link} href="mailto:info@insultech-inc.com">
          info@insultech-inc.com
        </a>
      </div>
      <div className={styles.heading}>Sitemap</div>
      <div className={styles.sitemap}>
        <Link className={styles.link} to="/about/">
          About
        </Link>
        <Link className={styles.link} to="/team/">
          Team
        </Link>
        <Link className={styles.link} to="/press/">
          Press
        </Link>
        <Link className={styles.link} to="/services/">
          Services
        </Link>
        <Link className={styles.link} to="/case-studies/">
          Case Studies
        </Link>
        <Link className={styles.link} to="/clients/">
          Clients
        </Link>
        <Link className={styles.link} to="/photos/">
          Photos
        </Link>
        <Link className={styles.link} to="/contact/">
          Contact
        </Link>
      </div>
    </div>
  );
}

export default FooterMenu;
