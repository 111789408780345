import React from "react";
import styles from "./index.module.css";

function Section(props) {
  return (
    <div
      className={`${styles.el} ${props.theme &&
        styles[props.theme]} ${props.contact && styles.contact}`}
    >
      {props.children}
    </div>
  );
}

export default Section;
