import React from "react";
import styles from "./index.module.css";

function Container(props) {
  return (
    <div className={`${styles.el} ${props.contact && styles.contact}`}>
      {props.children}
    </div>
  );
}

export default Container;
