import React from "react";
import styles from "./index.module.css";

const elements = {
  div: "div",
  h1: "h1",
  h2: "h2",
  h3: "h3",
};

function Heading(props) {
  return React.createElement(
    elements[props.as] || elements.div,
    { className: styles.el, ...props },
    props.children
  );
}

export default Heading;
