import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";
import "./index.css";
import styles from "./index.module.css";

function Layout(props) {
  return (
    <div className={styles.el}>
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
}

export default Layout;
