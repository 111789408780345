import { Link } from "gatsby";
import React from "react";
import styles from "./index.module.css";

const elements = {
  button: "button",
  link: Link,
};

function Button(props) {
  return React.createElement(
    elements[props.as] || elements.link,
    {
      className: `${styles.el} ${styles[props.background]} ${props.size &&
        styles[props.size]} ${props.outline && styles[props.outline]}`,
      ...props,
    },
    props.children
  );
}

export default Button;
