import React from "react";
import styles from "./FooterLocations.module.css";

function FooterLocations() {
  return (
    <div className={styles.el}>
      <div className={styles.heading}>Locations</div>
      <div className={styles.locations}>
        <div className={styles.location}>
          <span>
            <strong>Main Office</strong>
          </span>
          <span>5724 Industry Lane</span>
          <span>Frederick, MD 21704</span>
        </div>
        <div className={styles.location}>
          <span>
            <strong>Office/Warehouse</strong>
          </span>
          <span>6761 Mid Cities Avenue</span>
          <span>Beltsville, MD 20705</span>
        </div>
        <div className={styles.location}>
          <span>
            <strong>Warehouse</strong>
          </span>
          <span>7619 Doane Dr.</span>
          <span>Manassas, VA 20109</span>
        </div>
      </div>
    </div>
  );
}

export default FooterLocations;
