import { Link } from "gatsby";
import React from "react";
import Logo from "../../images/insultech-logo-solo.jpg";
import styles from "./HeaderLogo.module.css";

function HeaderLogo() {
  return (
    <Link className={styles.el} to="/">
      <img src={Logo} alt="Insul-tech Inc." />
    </Link>
  );
}

export default HeaderLogo;
