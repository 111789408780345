import Container from "components/Container";
import Form from "components/Form";
import Heading from "components/Heading";
import Section from "components/Section";
import { Link } from "gatsby";
import React from "react";
import Map from "../../images/insultech-office-map.jpg";
import styles from "./index.module.css";

function Contact() {
  return (
    <Section contact theme="blue">
      <Container contact>
        <div className={styles.heading}>
          <Heading as="h2">
            <Link to="/contact/">Contact Us</Link>
          </Heading>
        </div>
        <div className={styles.content}>
          <div className={styles.form}>
            <Form />
          </div>
          <Link to="/contact/" className={styles.map}>
            <img src={Map} alt="Insul-tech Inc. Offices" />
          </Link>
        </div>
      </Container>
    </Section>
  );
}

export default Contact;
