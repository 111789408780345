import React from "react";
import FooterAffilations from "./FooterAffiliations";
import FooterLocations from "./FooterLocations";
import FooterMenu from "./FooterMenu";
import styles from "./index.module.css";

function Footer() {
  return (
    <div className={styles.el}>
      <div className={styles.container}>
        <FooterMenu />
        <FooterLocations />
        <FooterAffilations />
      </div>
    </div>
  );
}

export default Footer;
