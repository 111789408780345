import { Link } from "gatsby";
import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import styles from "./HeaderMenuDesktop.module.css";

function HeaderMenuDesktop(props) {
  return (
    <div className={styles.el}>
      <div className={styles.nav}>
        <div className={styles.navDropdown}>
          <div
            className={`${styles.navLink} ${
              styles.navDropdownLink
            } ${new RegExp(["about", "team", "press"].join("|")).test(
              props.currentPath
            ) && styles.active}`}
          >
            Company <MdKeyboardArrowDown />
          </div>
          <div className={styles.dropdown}>
            <div className={styles.dropdownPanel}>
              <Link
                activeClassName={styles.active}
                className={styles.dropdownLink}
                to="/about/"
              >
                About
              </Link>
              <Link
                activeClassName={styles.active}
                className={styles.dropdownLink}
                to="/team/"
              >
                Team
              </Link>
              <Link
                activeClassName={styles.active}
                className={styles.dropdownLink}
                to="/press/"
              >
                Press
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.navDropdown}>
          <div
            className={`${styles.navLink} ${
              styles.navDropdownLink
            } ${new RegExp(
              ["case-studies", "clients", "photos"].join("|")
            ).test(props.currentPath) && styles.active}`}
          >
            Projects <MdKeyboardArrowDown />
          </div>
          <div className={styles.dropdown}>
            <div className={styles.dropdownPanel}>
              <Link
                activeClassName={styles.active}
                className={styles.dropdownLink}
                to="/case-studies/"
              >
                Case Studies
              </Link>
              <Link
                activeClassName={styles.active}
                className={styles.dropdownLink}
                to="/clients/"
              >
                Clients
              </Link>
              <Link
                activeClassName={styles.active}
                className={styles.dropdownLink}
                to="/photos/"
              >
                Photos
              </Link>
            </div>
          </div>
        </div>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/services/"
        >
          Services
        </Link>
        <Link
          activeClassName={styles.active}
          className={styles.navLink}
          to="/contact/"
        >
          Contact
        </Link>
      </div>
    </div>
  );
}

export default HeaderMenuDesktop;
