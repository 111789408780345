import React from "react";
import Button from "components/Button";
import styles from "./index.module.css";

function Form() {
  return (
    <form
      method="POST"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      name="contact"
    >
      <input type="hidden" name="bot-field" />
      <div className={styles.inputs}>
        <input
          className={styles.input}
          type="text"
          name="Name"
          required={true}
          placeholder="Name"
        />
        <input
          className={styles.input}
          type="text"
          name="Company"
          required={true}
          placeholder="Company"
        />
        <input
          className={styles.input}
          type="email"
          name="Email"
          required={true}
          placeholder="Email address"
          autoComplete="email"
        />
        <textarea
          className={styles.textarea}
          type="text"
          name="Message"
          required={true}
          placeholder="Your message"
          rows="4"
        />
        <div className={styles.cta}>
          <Button as="button" background="blue" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
}

export default Form;
