import { globalHistory as history } from "@reach/router";
import Button from "components/Button";
import React, { useState } from "react";
import HeaderLogo from "./HeaderLogo";
import HeaderMenuDesktop from "./HeaderMenuDesktop";
import HeaderMenuMobile from "./HeaderMenuMobile";
import styles from "./index.module.css";

function Header() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <header className={styles.el}>
        <div className={styles.container}>
          <HeaderLogo />
          <HeaderMenuDesktop currentPath={history.location.pathname} />
          <div className={styles.mobileButton}>
            <Button
              as="button"
              background="blue"
              onClick={() => setOpen(!open)}
              onKeyDown={() => false}
              size="small"
              tabIndex="0"
            >
              {open ? "Close" : "Menu"}
            </Button>
          </div>
        </div>
      </header>
      <HeaderMenuMobile active={open} currentPath={history.location.pathname} />
    </>
  );
}

export default Header;
